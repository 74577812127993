import { lazy } from 'react';
import Loadable from 'components/Loadable';
import PrivateRoute from './PrivateRoute';

const RawData = Loadable(lazy(() => import('pages/rawData')));

const SensorRoutes = {
  element: <PrivateRoute isAdminLayout={false} />,
  children: [
    {
      path: '/raw/:siteId/:sensorType/:sensorId',
      element: <RawData />,
    },
  ],
};

export default SensorRoutes;
