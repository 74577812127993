import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import SiteRoutes from './SiteRoutes';
import SensorRoutes from './SensorRoutes';
import AdminRoutes from './AdminRoutes';
import ReportRoutes from './ReportRoutes';
import RawDataRoutes from './RawDataRoutes';

export default function ThemeRoutes() {
  return useRoutes([SiteRoutes, SensorRoutes, LoginRoutes, AdminRoutes, ReportRoutes, RawDataRoutes]);
}
